export const WESTPAC_CC_PREFIX = [
    '429813',
    '516349',
    '516369',
    '516328',
    '516366',
    '429317',
    '471572',
    '516315',
    '516325',
    '516335',
    '516345',
    '516355',
    '516365',
    '516375',
    '516385',
    '555003',
]
