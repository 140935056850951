import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { FormWrapper } from 'modules/PaymentProfile/styles'
import { BackendPostDirectDebit } from 'modules/PaymentProfile/reducers/type'
import { Checkbox, Input } from '@myob/myob-widgets'
import {
    ErrorMessages,
    KEY_CODES,
    validationRules,
} from 'modules/PaymentProfile/components/EditDirectDebitCardForm/type'
import { IntermediatePaymentCard } from 'modules/PaymentProfile/type'
import { onFormBlur, onFormChange } from 'helpers/form'
import { formatAccountName, isContain8Digits } from 'modules/PaymentProfile/components/EditDirectDebitCardForm/helper'
import { useSelector } from 'react-redux'
import { RootState } from 'stores'
import { digestPaymentProfileData } from 'helpers/paymentProfile'

type FormProps = {
    onChange: (update: IntermediatePaymentCard) => void
    isActive: boolean
}
const initState = {
    directDebit: {
        bank_name: '',
        bsb: '',
        account_number: '',
    },
    errors: {
        bank_name: {
            isValid: false,
            value: '',
        },
        bsb: {
            isValid: false,
            value: '',
        },
        account_number: {
            isValid: false,
            value: '',
        },
        terms: {
            isValid: false,
            value: '',
        },
    },
}

const DirectDebitFormFieldsAU: React.FC<FormProps> = ({ isActive, onChange }) => {
    const [directDebit, setDirectDebit] = useState<BackendPostDirectDebit>(initState.directDebit)
    const [errors, setErrors] = useState<ErrorMessages>(initState.errors)
    const [termsOfUseChecked, setTermsOfUse] = useState(false)

    const paymentProfile = useSelector((state: RootState) => state.paymentProfileAccountView)
    const isWestPacPromotion = digestPaymentProfileData(paymentProfile).isWestPacPromotion

    useEffect(() => {
        const isValidFormData = Object.values(errors).filter((item) => !item.isValid).length === 0
        const data = { detail: isValidFormData ? directDebit : undefined, termsOfUseChecked }
        onChange(data)
    }, [directDebit, termsOfUseChecked, errors, onChange])

    useEffect(() => {
        setTermsOfUse(false)
        setDirectDebit(initState.directDebit)
        setErrors(initState.errors)
    }, [isActive])

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { value, name } = onFormChange(event, validationRules)
        setDirectDebit((prevState) => ({
            ...prevState,
            [name]: value,
        }))
    }

    const onBlur = useCallback(
        (event: React.FormEvent<HTMLInputElement>): void => {
            const { value, isValid, name } = onFormBlur(event, validationRules)
            setErrors({ ...errors, [name]: { value, isValid } })
        },
        [errors],
    )
    const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
        const name = event.currentTarget.name
        const value = event.currentTarget.value
        const keyCode = event.keyCode || event.which

        if (keyCode < KEY_CODES.digit0 || keyCode > KEY_CODES.digit9) {
            event.preventDefault()
        }
        const maxLength = validationRules[name].maxLength
        if (maxLength && value.length >= maxLength) {
            event.preventDefault()
        }
    }

    const onAccountNameInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (
            isContain8Digits(directDebit.bank_name) &&
            event.keyCode >= KEY_CODES.digit0 &&
            event.keyCode <= KEY_CODES.digit9
        ) {
            event.preventDefault()
        }
    }

    const onAccountNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value, name } = onFormChange(event, validationRules)
        setDirectDebit((prevState) => ({
            ...prevState,
            [name]: formatAccountName(value),
        }))
    }
    const handleOnTermsChange = () => {
        setTermsOfUse(!termsOfUseChecked)
    }
    useLayoutEffect(() => {
        setErrors((prevState) => ({
            ...prevState,
            terms: {
                value: termsOfUseChecked ? '' : validationRules.terms.errorMessage,
                isValid: termsOfUseChecked,
            },
        }))
    }, [termsOfUseChecked])

    const onBlurBSB = (event: Event) => {
        const bsb = (event.target as HTMLInputElement).value

        if (bsb.length < Number(validationRules.bsb?.length) || bsb.length > Number(validationRules.bsb?.maxLength)) {
            setErrors((prevState) => ({
                ...prevState,
                bsb: {
                    value: 'Please enter a valid BSB',
                    isValid: false,
                },
            }))
        } else if (isWestPacPromotion && !(bsb.startsWith('73') || bsb.startsWith('03'))) {
            setErrors((prevState) => ({
                ...prevState,
                bsb: {
                    value: 'Please add a valid Westpac Business One Plus bank account',
                    isValid: false,
                },
            }))
        } else {
            setErrors((prevState) => ({
                ...prevState,
                bsb: {
                    value: '',
                    isValid: true,
                },
            }))
        }
    }

    const onBlurAccountNumber = (event: Event) => {
        const accountNumber = (event.target as HTMLInputElement).value

        if (accountNumber.length < Number(validationRules.account_number?.length)
            || accountNumber.length > Number(validationRules.account_number?.maxLength)
            || /^0+$/.test(accountNumber)) {
            setErrors((prevState) => ({
                ...prevState,
                account_number: {
                    value: 'Please enter a valid bank account number',
                    isValid: false,
                },
            }))
        } else {
            setErrors((prevState) => ({
                ...prevState,
                account_number: {
                    value: '',
                    isValid: true,
                },
            }))
        }
    }

    return (
        <>
            <FormWrapper>
                <Input
                    name="bank_name"
                    label="Account name *"
                    type="text"
                    value={directDebit.bank_name}
                    errorMessage={errors.bank_name.value}
                    onChange={onAccountNameChange}
                    onKeyDown={onAccountNameInputKeyDown}
                    onBlur={onBlur}
                />
                <Input
                    name="bsb"
                    label="BSB *"
                    type="text"
                    value={directDebit.bsb}
                    errorMessage={errors.bsb.value}
                    onKeyPress={onKeyPress}
                    onChange={onInputChange}
                    onBlur={onBlurBSB}
                />
                <Input
                    name="account_number"
                    label="Account number *"
                    value={directDebit.account_number}
                    errorMessage={errors.account_number.value}
                    onKeyPress={onKeyPress}
                    onChange={onInputChange}
                    onBlur={onBlurAccountNumber}
                />
            </FormWrapper>
            <div>
                <Checkbox
                    name="terms"
                    label="I agree that:"
                    errorMessage={errors.terms.value}
                    checked={termsOfUseChecked}
                    onChange={handleOnTermsChange}
                />
                <div>
                    I have sufficient authority to make this direct debit request on behalf of the owner(s) of this bank
                    account and have read and agreed to the&nbsp;
                    <a
                        href="https://www.myob.com/au/legal/direct-debit-terms"
                        target="_blank"
                        rel="noopener noreferrer"
                        key="terms"
                    >
                        Direct Debit Terms & Conditions.
                    </a>
                </div>
            </div>
        </>
    )
}

export default DirectDebitFormFieldsAU
