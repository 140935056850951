import { Region } from 'stores/type'

export enum ECardType {
    CreditCard = 'Credit Card',
    DirectDebit = 'Direct Debit',
    OnAccount = 'On Account',
}

export enum EPaymentTermName {
    Cash = 'Cash',
}

export enum EPaymentSchedule {
    Monthly = 'Monthly',
}

export enum EScheduleChargeDay {
    First = '1st',
}

export enum ECardStatus {
    Active = 'Active',
    Inactive = 'Inactive',
}

export enum ECreditCardType {
    Visa = 'Visa',
    MasterCard = 'MasterCard',
    AmericanExpress = 'American Express',
}

export enum EPaymentProfileActionType {
    POST_REQUESTED = 'PAYMENT_PROFILE_POST_REQUESTED',
    POST_SUCCEEDED = 'PAYMENT_PROFILE_POST_SUCCEEDED',
}

interface IBasePaymentProfileItem {
    payment_term_name: string
    payment_schedule: string
    schedule_charge_day: string
    payment_method: ECardType
    status: ECardStatus
    primary_profile: boolean
}

export interface ICreditCardPaymentProfileItem extends IBasePaymentProfileItem {
    payment_method: ECardType.CreditCard
    credit_card: {
        name_on_card: string
        card_token: string
        last_four_digits: string
        type: ECreditCardType
        expiry_month: string
        expiry_year: string
    }
}

export interface IDirectDebitPaymentProfileItem extends IBasePaymentProfileItem {
    payment_method: ECardType.DirectDebit
    direct_debit: {
        bank_name: string
        bsb: string
        account_number: string
    }
}

export type IPaymentProfileItem =
    | IBasePaymentProfileItem
    | ICreditCardPaymentProfileItem
    | IDirectDebitPaymentProfileItem

export interface IPaymentProfile {
    is_in_westpac_promotion: boolean
    is_partner_pay: boolean
    region?: Region
    is_updating: boolean
    item: IPaymentProfileItem
}

export interface IPostPaymentProfileAction {
    type: EPaymentProfileActionType.POST_REQUESTED
    meta: { failureMessage: string; successMessage: string }
    payload: {
        params: unknown
        data: BackendPostPaymentProfile
    }
}

export interface IPaymentProfileAction {
    type: EPaymentProfileActionType
    data: IPaymentProfile[]
    error: Error
}

export interface BackendPostCreditCard {
    card_token: string
    name_on_card: string
    expiry_month: string
    expiry_year: string
    type: string
    last_four_digits: string
    first_six_digits: string
}

export interface BackendPostDirectDebit {
    bank_name: string
    bsb: string
    account_number: string
}

export type BackendPostPaymentProfile = BackendPostCreditCard | BackendPostDirectDebit
