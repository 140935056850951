import React from 'react'
import { Card, PageState } from '@myob/myob-widgets'
import noAccessImg from 'assets/images/noAccess.svg'
import { Region } from 'stores/type'
import ContactUs from '../ContactUs'
import { AdditionalInfo } from 'helpers/type'

export type Props = {
    pageName: string
    region?: Region
    type?: string
    additionalInfo?: AdditionalInfo
}

export const Unauthorised: React.FC<Props> = ({ pageName, region, type, additionalInfo }) => {
    const divStyle: React.CSSProperties = {
        textAlign: 'left',
    }

    return (
        <div data-testid="unauthorized-component">
            <Card>
                {additionalInfo && type === 'NOT_PRIMARY_CONTACT_EMAIL' ? (
                    <PageState
                        title="You can't view billing information"
                        description={
                            <>
                                <div style={divStyle}>
                                    You don&apos;t have access to manage your MYOB billing information.
                                </div>
                                <div style={divStyle}>The account owner will be able to help you:</div>
                                <div style={divStyle}>
                                    {additionalInfo.first_name} {additionalInfo.last_name}
                                </div>
                                <div style={divStyle}>{additionalInfo.primary_email}</div>
                            </>
                        }
                        image={<img src={noAccessImg} alt="Unauthorised image" />}
                    />
                ) : (
                    <PageState
                        title="You don’t have the access required to see the billing information for this account!"
                        description={
                            <>
                                Please <ContactUs pageName={pageName} region={region} /> so we can assist you if
                                required!
                            </>
                        }
                        image={<img src={noAccessImg} alt="Unauthorised image" />}
                    />
                )}
            </Card>
        </div>
    )
}
