/** @jsx jsx */
import { CacheProvider, jsx } from '@emotion/react'
import createCache from '@emotion/cache'
import { Provider } from 'react-redux'
import store from './stores'
import Routes from './router'
import { BrowserRouter } from 'react-router-dom'

export default function Root() {
    return (
        <Provider store={store}>
            <CacheProvider
                value={createCache({
                    key: 'my-account-features',
                })}
            >
                <BrowserRouter>
                    <Routes />
                </BrowserRouter>
            </CacheProvider>
        </Provider>
    )
}
