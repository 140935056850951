import {
    BackendPostCreditCard,
    BackendPostPaymentProfile,
    ECardStatus,
    ECardType,
    EPaymentSchedule,
    EPaymentTermName,
    EScheduleChargeDay,
    ICreditCardPaymentProfileItem,
    IDirectDebitPaymentProfileItem,
    IPaymentProfile,
    IPaymentProfileItem,
} from 'modules/PaymentProfile/reducers/type'
import { PaymentProfileState } from 'modules/PaymentProfile/reducers'
import { EHttpStatusCode } from './type'
import { WESTPAC_CC_PREFIX } from '../constants/constants'

export function isCreditCardPaymentProfile(
    paymentProfileItem: IPaymentProfileItem
): paymentProfileItem is ICreditCardPaymentProfileItem {
    return paymentProfileItem.payment_method === ECardType.CreditCard
}

export function isDirectDebitPaymentProfile(
    paymentProfileItem: IPaymentProfileItem
): paymentProfileItem is IDirectDebitPaymentProfileItem {
    return paymentProfileItem.payment_method === ECardType.DirectDebit
}

export function isUnderPartnerPayAccount(paymentProfiles: IPaymentProfile[]): boolean {
    if (paymentProfiles.length != 1) {
        return false
    }
    return paymentProfiles[0].is_partner_pay
}

export function filterByStatus(paymentProfiles: IPaymentProfile[], status: ECardStatus): IPaymentProfile[] {
    return paymentProfiles?.filter(
        (paymentProfile) => 'item' in paymentProfile && paymentProfile.item?.status === status
    )
}

export function filterActivePaymentProfiles(paymentProfiles: IPaymentProfile[]) {
    return filterByStatus(paymentProfiles, ECardStatus.Active)
}

export function hasOnAccountPaymentProfile(paymentProfiles: IPaymentProfile[]): boolean {
    return filterActivePaymentProfiles(paymentProfiles).some((p) => p.item.payment_method === ECardType.OnAccount)
}

export function isAllOnAccountPaymentProfile(paymentProfiles: IPaymentProfile[]): boolean {
    return filterActivePaymentProfiles(paymentProfiles).every((p) => p.item.payment_method === ECardType.OnAccount)
}

export function isMultipleActivePaymentProfiles(paymentProfiles: IPaymentProfile[]): boolean {
    return filterActivePaymentProfiles(paymentProfiles).length > 1
}

export function isPaymentTermNameAllSupported(paymentProfiles: IPaymentProfile[]): boolean {
    return paymentProfiles.every((p) => p.item.payment_term_name === EPaymentTermName.Cash)
}

export function isScheduleChargeDayAllSupported(paymentProfiles: IPaymentProfile[]): boolean {
    return paymentProfiles.every((p) => p.item.schedule_charge_day === EScheduleChargeDay.First)
}

export function isPaymentScheduleAllSupported(paymentProfiles: IPaymentProfile[]): boolean {
    return paymentProfiles.every((p) => p.item.payment_schedule === EPaymentSchedule.Monthly)
}

export function isAllSupportedPaymentProfiles(paymentProfiles: IPaymentProfile[]): boolean {
    const activePaymentProfiles = filterActivePaymentProfiles(paymentProfiles)
    return (
        isPaymentTermNameAllSupported(activePaymentProfiles) &&
        isPaymentScheduleAllSupported(activePaymentProfiles) &&
        isScheduleChargeDayAllSupported(activePaymentProfiles)
    )
}
export function getSingleActivePaymentProfileIfThereIsOnlyOne(
    paymentProfiles: IPaymentProfile[]
): IPaymentProfile | null {
    return filterActivePaymentProfiles(paymentProfiles)[0] || null
}

export function isPostCreditCard(detail: BackendPostPaymentProfile): detail is BackendPostCreditCard {
    return 'last_four_digits' in detail
}

export enum Permission {
    Denied = 'denied',
    Granted = 'granted',
}

export interface PaymentProfileDigest {
    isError: boolean
    statusCode: number
    isLoading: boolean
    isUpdating: boolean
    hasOnAccount: boolean
    isAllOnAccount: boolean
    isPartnerPay: boolean
    isMultiActivePaymentProfiles: boolean
    missesActivePaymentProfile: boolean
    supportUpdateMultiPaymentProfiles: boolean
    activePaymentProfile: IPaymentProfile | null
    permission: Permission
    permissionDeniedReason: Reason | null
    isWestPacPromotion: boolean
}

export type Reason = string

export function getReasonForDenyingAccess(digest: PaymentProfileDigest): Reason | null {
    if (digest.isLoading) {
        return 'IsLoading'
    }
    if (digest.hasOnAccount) {
        return digest.isAllOnAccount ? 'AllOnAccount' : 'PartialOnAccount'
    }
    if (digest.statusCode === EHttpStatusCode.FORBIDDEN) {
        return 'Unauthorised'
    }
    if (digest.isError) {
        return 'ErrorFetchingData'
    }
    if (digest.isMultiActivePaymentProfiles) {
        return digest.supportUpdateMultiPaymentProfiles ? null : 'UnsupportedMultiActivePaymentProfiles'
    }
    if (digest.isPartnerPay) {
        return 'PartnerPays'
    }
    if (digest.missesActivePaymentProfile) {
        return 'NoPaymentProfileFound'
    }
    return null
}

export function digestPaymentProfileData(state: PaymentProfileState): PaymentProfileDigest {
    const digest: PaymentProfileDigest = {
        isError: false,
        statusCode: 0,
        missesActivePaymentProfile: true,
        activePaymentProfile: null,
        isLoading: false,
        isUpdating: false,
        isMultiActivePaymentProfiles: false,
        supportUpdateMultiPaymentProfiles: false,
        hasOnAccount: false,
        isAllOnAccount: false,
        isPartnerPay: false,
        permission: Permission.Denied,
        permissionDeniedReason: null,
        isWestPacPromotion: false,
    }
    if (state.error) {
        const res = { ...digest, isError: true }
        const errorMessage = state.error.message ? state.error.message : state.error
        if (state.error.status) {
            res.statusCode = state.error.status
        }
        res.permissionDeniedReason = getReasonForDenyingAccess(res)

        // Keep business ID flow not 403 error GA content view tracking stay same
        res.permissionDeniedReason += res.statusCode !== EHttpStatusCode.FORBIDDEN ? `: ${errorMessage}` : ''

        return res
    }
    const paymentProfiles = state.items
    if (paymentProfiles == null) {
        const res = { ...digest, isLoading: true }
        res.permissionDeniedReason = getReasonForDenyingAccess(res)
        return res
    }
    digest.isUpdating = paymentProfiles.some((p) => p.is_updating)
    digest.statusCode = EHttpStatusCode.OK

    if (isUnderPartnerPayAccount(paymentProfiles)) {
        const res = { ...digest, isPartnerPay: true }
        res.permissionDeniedReason = getReasonForDenyingAccess(res)
        return res
    }

    const activePaymentProfile = getSingleActivePaymentProfileIfThereIsOnlyOne(paymentProfiles)
    if (activePaymentProfile == null) {
        const res = { ...digest, missesActivePaymentProfile: true }
        res.permissionDeniedReason = getReasonForDenyingAccess(res)
        return res
    }

    digest.activePaymentProfile = activePaymentProfile
    digest.missesActivePaymentProfile = false

    digest.isMultiActivePaymentProfiles = isMultipleActivePaymentProfiles(paymentProfiles)
    digest.hasOnAccount = hasOnAccountPaymentProfile(paymentProfiles)
    digest.isAllOnAccount = isAllOnAccountPaymentProfile(paymentProfiles)
    digest.supportUpdateMultiPaymentProfiles = isAllSupportedPaymentProfiles(paymentProfiles)

    digest.permissionDeniedReason = getReasonForDenyingAccess(digest)
    if (digest.permissionDeniedReason == null) {
        digest.permission = Permission.Granted
    }

    digest.isWestPacPromotion = activePaymentProfile?.is_in_westpac_promotion === true

    return digest
}

export function isInvalidPaymentProfile(state: PaymentProfileState): boolean {
    const digest = digestPaymentProfileData(state)

    return digest.isMultiActivePaymentProfiles || digest.missesActivePaymentProfile || digest.hasOnAccount
}

export function validateWestpacCredit(firstSixDigits: string): boolean {
    return WESTPAC_CC_PREFIX.some((validPrefix) => firstSixDigits === validPrefix)
}
