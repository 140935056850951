import React, { useEffect, useState } from 'react'
import { Button, ButtonRow, Modal, Spinner } from '@myob/myob-widgets'
import { ga } from '@my-account/tools'
import { IntermediatePaymentCard, ValidatedPaymentCard } from 'modules/PaymentProfile/type'
import CreditCardForm from 'modules/PaymentProfile/components/EditCreditCardForm'
import { CustomisedModalBody } from 'modules/PaymentProfile/styles'
import * as telemetry from 'telemetry'
import { ETelemetryActionsAndLabels, ETelemetryNames } from 'telemetry/type'
import DirectDebitCardForm from '../EditDirectDebitCardForm'
import { sendGtmFormSubmitEvent } from '../../../../helpers/ga'
import { featureFlags } from '../../../../configs/FeatureFlags'
import { digestPaymentProfileData, validateWestpacCredit } from 'helpers/paymentProfile'
import { useSelector } from 'react-redux'
import { RootState } from 'stores'

type ModalProps = {
    isProcessing?: boolean
    cancelModal: () => void
    onSaveValidData: (data: ValidatedPaymentCard) => void
}

export enum FormType {
    CreditCard = 'CreditCard',
    DirectDebit = 'DirectDebit',
    None = 'None',
}

export enum Progress {
    Open = 'open',
    Save = 'save',
    Cancel = 'cancel',
    Abandon = 'abandon',
}

function track(progress: Progress, activeForm: FormType) {
    telemetry.track(ETelemetryNames.PaymentProfile, {
        action: ETelemetryActionsAndLabels.Edit,
        label: ETelemetryActionsAndLabels.Edit,
        editData: { progress, selectedPaymentMethod: activeForm },
    })
}

const EditModal: React.FC<ModalProps> = ({ cancelModal, onSaveValidData, isProcessing }) => {
    const [formData, setFormData] = useState<IntermediatePaymentCard>(new IntermediatePaymentCard())
    const [formIsValid, setFormIsValid] = useState(false)
    const [activeFormType, setActiveForm] = useState(FormType.None)
    const [emittedTrackStart, setEmittedTrackStart] = useState(false)

    const paymentProfile = useSelector((state: RootState) => state.paymentProfileAccountView)
    const isWestPacPromotion = digestPaymentProfileData(paymentProfile).isWestPacPromotion

    const formDataIsValid = (card: IntermediatePaymentCard, activeFormType: FormType) => {
        switch (activeFormType) {
            case FormType.CreditCard:
                if (isWestPacPromotion) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    return card.detail && validateWestpacCredit(card.detail?.first_six_digits)
                }
                return card.detail
            case FormType.DirectDebit:
                return card.termsOfUseChecked && card.detail
            default:
                return false
        }
    }

    const cancelModalWithTracking = () => {
        const isCancel = formData.detail == null
        track(isCancel ? Progress.Cancel : Progress.Abandon, activeFormType)
        if (!isCancel) {
            ga.push({
                event: 'analyticElementClick',
                'gtm.elementId': `${activeFormType}_Abandon`,
            })
        }
        cancelModal()
    }

    const onClickSaveBtn = () => {
        track(Progress.Save, activeFormType)
        ga.push({
            event: 'genericFormSubmit',
            elementId: activeFormType,
        })
        sendGtmFormSubmitEvent('pp', `edit pp modal submit ${activeFormType}`)
        onSaveValidData(formData as ValidatedPaymentCard)
    }
    if (!emittedTrackStart) {
        track(Progress.Open, activeFormType)
        setEmittedTrackStart(true)
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    useEffect(() => setFormIsValid(formDataIsValid(formData, activeFormType)), [formData, activeFormType])
    return (
        <Modal
            canClose={!isProcessing}
            title="Update payment details"
            data-testid="updatePaymentDetailsModal"
            onCancel={cancelModalWithTracking}
        >
            <Modal.Body>
                <CustomisedModalBody>
                    <CreditCardForm
                        onHeaderClick={() => {
                            setActiveForm(FormType.CreditCard)
                        }}
                        onChange={setFormData}
                        isActive={activeFormType == FormType.CreditCard}
                    />
                    <DirectDebitCardForm
                        onHeaderClick={() => {
                            setActiveForm(FormType.DirectDebit)
                        }}
                        onChange={setFormData}
                        isActive={activeFormType == FormType.DirectDebit}
                    />
                    {isProcessing && <Spinner />}
                </CustomisedModalBody>
            </Modal.Body>
            <Modal.Footer>
                <ButtonRow>
                    <Button disabled={!!isProcessing} type="secondary" onClick={cancelModalWithTracking}>
                        Cancel
                    </Button>
                    <Button disabled={!formIsValid || !!isProcessing} onClick={onClickSaveBtn}>
                        Save
                    </Button>
                </ButtonRow>
            </Modal.Footer>
        </Modal>
    )
}

export default EditModal
